import * as React from "react";
import { LinkOutlineButton } from "../../components/OutlineButton";
import "./VRExperience.scss";

interface IVrExperienceProps {}

export const VrExperience: React.FunctionComponent<IVrExperienceProps> = (props) => {
  return (<div className="VrExperience">
      <div>
        <h1>See Everything</h1>
        <LinkOutlineButton text="VIEW 360° EXPERIENCE" to="/vr" />
      </div>
  </div>);
};
