import "./MediaResources.scss";

import * as React from "react";
import { Header } from "../../components/Header";
import Footer from "../../components/Footer";
import { AnchorOutlineButton, OutlineButton } from "../../components/OutlineButton";
import CES2021 from "./img/ces2021_innovationawardsboi-png.png";
import CES2020 from "./img/ces2020-innovation-award-honoree-recipient.png";
import SUSTAINABILITY from "./img/SUSTAINABILITY-2020.png";
import MAGAZINE from "./img/JAN21-TheFurrow.png";
import classNames from "classnames";
import { ModalContext } from "../../components/modal/Modal";

interface IVideoResourcesProps {}

const urlRoot = "http://jd-ces-2021.s3.amazonaws.com/media-resources";

export const VideoResources: React.FunctionComponent<IVideoResourcesProps> = (props) => {
  const [ category, setCategory ] = React.useState<"all" | "field" | "vr">("all");
  const { handleModal } = React.useContext(ModalContext);

  const vidsField = [
    "76370_190513_GEP_8RX370_1775NT_Working_Aerial_DJI_0002_004",
    "76370_190513_GEP_8RX_1775NT_Planter_working_A015_C005_05134A_003",
    "76370_190513_GEP_8RX_1775NT_Planter_working_A015_C006_0513XV_006",
    "76370_190513_GEP_8RX_1775NT_Planter_working_A015_C028_05140O",
    "78761_06_77373_190828_GEP_combine_X950_HD50F_working_P1024629",
    "78761_11_77373_190906_GEP_combine_X950_Wheat_aerial full unload on the go and running in field_DJI_0004",
    "78761_12_77373_190906_GEP_combine_X950_Wheat_aerial machine running in field and unloading_DJI_0032",
    "78761_14_77373_190906_GEP_combine_X950_Wheat_aerial machine running in field_DJI_0003_1",
    "78761_32_77449_191007_GEP_AERIAL_X950_COMBINE_Harvesting_DJI_0024",
    "78761_37_77449_191007_GEP_ronin_X950_8R_harvesting_P1366560",
    "80395_200921_SJP_X9_1100_HD50__Day3C_DJI_0013",
    "80395_200921_SJP_X9_1100_HDR_HDF_50_DJI_0013_1",
    "80395_200921_SJP_X9_1100_S790_HDR_HDF_50_Day2_DJI_0017"
  ];

  const vidsVR = [
    "John Deere Build December 29, 2020 - Clip 01 - Introduciton",
    "John Deere Build December 29, 2020 - Clip 02 - Planting Slow Motion",
    "John Deere Build December 29, 2020 - Clip 03 - Fast Motion Planting",
    "John Deere Build December 29, 2020 - Clip 04 - Interior Cab"
  ];

  const vidList = [];

  if (category === "all" || category === "field") vidList.push(...vidsField);

  if (category === "all" || category === "vr") vidList.push(...vidsVR);

  return (
    <div className="VideoResources">
      <div className="filters">
        <div onClick={() => setCategory("all")} className={classNames({ active: category === "all" })}>
          All
        </div>
        <div onClick={() => setCategory("field")} className={classNames({ active: category === "field" })}>
          Field
        </div>
        <div onClick={() => setCategory("vr")} className={classNames({ active: category === "vr" })}>
          Virtual Reality
        </div>
      </div>
      {vidList.map((x) => (
        <div
          className="img-wrapper"
          onClick={() =>
            handleModal({
              lightbox: true,
              video: `${urlRoot}/video/${x.replaceAll(" ", "%20")}.mp4`
            })}
        >
          <div
            className="img-thumb"
            style={{ backgroundImage: `url(${urlRoot}/video/thumb/${x.replaceAll(" ", "%20")}.jpg)` }}
          />
          <div className="thumb-overlay">
            <span>View</span>
          </div>
        </div>
      ))}
    </div>
  );
};

interface IImageResourcesProps {}

export const ImageResources: React.FunctionComponent<IImageResourcesProps> = (props) => {
  const [ category, setCategory ] = React.useState<"all" | "tractor" | "x9" | "presenters" | "event">("all");
  const { handleModal } = React.useContext(ModalContext);

  const imgTractor = [
    "71_r4g048434_rrd.jpg",
    "72_r4f064245_rrd.jpg",
    "72_r4f064278_rrd.jpg",
    "72_r4f064281_rrd.jpg",
    "72_r4f064300_rrd.jpg",
    "72_r4f064304_rrd.jpg",
    "72_r4f064314_VS.jpg",
    "72_r4f064323_rrd.jpg",
    "72_r4f064332_rrd.jpg",
    "72_r4f064337_rrd.jpg",
    "72_r4f064350_rrd.jpg",
    "72_r4f064366_rrd.jpg"
  ];

  const imgX9 = [ "72_r4f086046_EC_rrd.jpg", "72_r4f086435_rrd_02.jpg" ];

  const imgPresenters = [
    "DanLeibfried.jpg",
    "DeannaKovar.jpg",
    "JahmyHindman.jpg",
    "JennyOse.jpg",
    "JulianSanchez.jpg",
    "LaneArthur.jpg",
    "NancyPost.jpg",
    "ThanHartsock.jpg",
  ]

  const imgEvent = [
    "72_20210110-corp-01-03.jpg",
    "72_20210110-corp-01-04.jpg",
    "72_20210110-corp-01-142.jpg",
    "72_20210110-corp-01-145.jpg",
    "72_20210110-corp-01-95.jpg",
    "Deanna_Kovar.jpg",
    "Jahmy_Hindman.jpg",
    "Jenny_Ose.jpg",
    "Jon_Ebert.jpg",
    "Julian_Sanchez.jpg",
    "Lane_Arthur.jpg",
    "Laurel_Caes.jpg",
  ]

  const imgList: { type: string; path: string; }[] = [];

  if (category === "all" || category === "tractor") imgList.push(...imgTractor.map(path => ({ type: "tractor", path})));

  if (category === "all" || category === "x9") imgList.push(...imgX9.map(path => ({ type: "x9", path})));

  if (category === "all" || category === "presenters") imgList.push(...imgPresenters.map(path => ({ type: "presenters", path})));

  if (category === "all" || category === "event") imgList.push(...imgEvent.map(path => ({ type: "event", path})));

  function labelFor(x: { type: string; path: string; }) {
    if(x.type === "presenters")
      return x.path.replace(/([a-z])([A-Z])/g, "$1 $2").replace(".jpg", "");

    if(x.type === "event" && x.path.indexOf("_") > 2)
      return x.path.replace("_", " ").replace(".jpg", "");

    return "View";
  }

  return (
    <div className="ImageResources">
      <div className="filters">
        <div onClick={() => setCategory("all")} className={classNames({ active: category === "all" })}>
          All
        </div>
        <div onClick={() => setCategory("tractor")} className={classNames({ active: category === "tractor" })}>
          Tractor/Planter
        </div>
        <div onClick={() => setCategory("x9")} className={classNames({ active: category === "x9" })}>
          X9 Combine
        </div>
        <div onClick={() => setCategory("presenters")} className={classNames({ active: category === "presenters" })}>
          Presenters
        </div>
        <div onClick={() => setCategory("event")} className={classNames({ active: category === "event" })}>
          Event
        </div>
      </div>
      <div className={classNames("images-grid", category)}>
        {imgList.map((x) => (
          <div
            className="img-wrapper"
            onClick={() =>
              handleModal({
                lightbox: true,
                img: `${urlRoot}/images/${x.path}`
              })}
          >
            <div className={classNames("img-thumb", x.type)} style={{ backgroundImage: `url(${urlRoot}/images/${x.path})` }} />
            <div className="thumb-overlay">
              <span>{labelFor(x)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

interface IMediaResourcesFooterProps {}

export const MediaResourcesFooter: React.FunctionComponent<IMediaResourcesFooterProps> = (props) => {
  return (
    <div className="MediaResourcesFooter">
      <div className="Accolades">
        <h2>Accolades</h2>
        <div>
          <a href="https://www.ces.tech/Innovation-Awards/Honorees/2021/Best-Of/J/John-Deere-X-Series-Combine.aspx" target="_blank" rel="noreferrer"><img src={CES2021} alt="" /></a>
          <h4>
            CES<sup>®</sup> INNOVATION AWARDS 2021 BEST OF INNOVATION
          </h4>
        </div>
        <div>
          <a href="https://www.ces.tech/Innovation-Awards/Honorees/2020/Honorees/J/John-Deere-8RX-Tractor.aspx" target="_blank" rel="noreferrer"><img src={CES2020} alt="" /></a>
          <h4>
            CES<sup>®</sup> INNOVATION AWARDS 2020 HONOREE
          </h4>
        </div>
        <div>
          <a href="https://www.gadgetmatch.com/gadgetmatch-awards-best-of-ces-2020/" target="_blank" rel="noreferrer"> <img src={SUSTAINABILITY} alt="" /></a>
          <h4>SUSTAINABILITY AWARD 2020</h4>
        </div>
      </div>
      <div className="Magazine">
        <div>
          <h2>
            125 Years of <em>the Furrow</em>
          </h2>
          <p>
            <em>The Furrow</em> tells stories for and about the American farmer, with the January 2021 edition
            commemorating its 125th year of serving those who serve the land.
          </p>
          <AnchorOutlineButton text="View January 2021 Issue" to="https://www.deere.com/en/publications/the-furrow/" />
        </div>
        <div>
          <img src={MAGAZINE} alt="" />
        </div>
      </div>
      <div className="Contacts">
        <div>
          <h4>Jon Ebert</h4>
          <p>
            Manager, North American Public &amp; Industry Relations
            <br />
            John Deere Ag &amp; Turf Division
          </p>
          <p className="bold">
            Mobile: (913) 302-0592<br />EbertJonathanP@JohnDeere.com
          </p>
        </div>
        <div>
          <h4>Laurel Caes</h4>
          <p>
            Public Relations Manager – Technology &amp; Innovation
            <br />
            John Deere Ag &amp; Turf Division
          </p>
          <p className="bold">
            Mobile: (913) 749-9768<br />CaesLaurelJ@JohnDeere.com
          </p>
        </div>
        <div>
          <h4>Jennifer Grabowski</h4>
          <p>
            Vice President
            <br />
            Racepoint Global
          </p>
          <p className="bold">
            Mobile: (508) 631-6974<br />JohnDeereMedia@racepointglobal.com
          </p>
        </div>
      </div>
      <div className="Links">
        <AnchorOutlineButton text="Supplier Request Form" to="https://jdsn.deere.com/wps/portal/jdsn/Home/Welcome/!ut/p/z1/tZNNb8IgGMe_ih48GkD6xrF1ale1yzS-lItpKzqcUmxZ3fbpRzd3WVKXpRkHyBMefvlB_gAK1oCKuOT7WPFMxEddR9TaTEd3gxE2UOh4gQndIQpC_8FcWn0EloACmgol1ROIDttCbC4sKbhiHVhVX3NrzwTL42MrzYRiQnWgzLNCslTxkrWKFymPnOVFhZIp34III2LuEggNRmzLtBlBBBPkOEkSGxZjFlj95kX1NqwZLtTn6WdLLQFeG24wIu1gb0hvAqFv9EKIfQIfUTi4H8MZggsTrErOLmAhsvykn3L-h-v53_Qav-m4Cf2HuGN5phafzWfD4QR52G6MrrX-P3TfaIAObmcBVd-AH85n6uqsVxF-VWDdNOzydHLwW_d5Nx1gIwrKdy_sXhcaue32B0sna2s!/dz/d5/L2dBISEvZ0FBIS9nQSEh/" active={true} />
        <AnchorOutlineButton text="John Deere Newsroom" to="https://www.deere.com/en/our-company/news-and-announcements/newsroom/" active={true}/>
      </div>
    </div>
  );
};

interface IMediaResourcesProps {}

export const MediaResources: React.FunctionComponent<IMediaResourcesProps> = (props) => {
  const [ tab, setTab ] = React.useState<"images" | "video">("images");

  return (
    <div className="MediaResources">
      <Header />
      <div className="page-content">
        <h1>Media Content</h1>
        <div className="tabs">
          <OutlineButton text="Images" onClick={() => setTab("images")} active={tab === "images"} />
          <OutlineButton text="Videos" onClick={() => setTab("video")} active={tab === "video"} />
        </div>
        {tab === "images" && <ImageResources />}
        {tab === "video" && <VideoResources />}
      </div>
      <MediaResourcesFooter />
      <Footer />
    </div>
  );
};
