import * as React from "react";
import { LinkOutlineButton } from "../../components/OutlineButton";
import "./GamePanel.scss";

interface IGamePanelProps {}

export const GamePanel: React.FunctionComponent<IGamePanelProps> = (props) => {
  return (
    <div className="GamePanel">
      <div>
        <h1>How Precise Are Your Planting Skills?</h1>
        <LinkOutlineButton text="Play the Game" to="/game/nointro" />
      </div>
    </div>
  );
};
