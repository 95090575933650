export const currentFrame = (index: number, ON_MOBILE: boolean) => {
  console.log(`On Mobile? ${ON_MOBILE}`);
  return `http://jd-ces-2021.s3.amazonaws.com/animation/${ON_MOBILE ? "mobile" : "desktop"}_intro/${ON_MOBILE ? "mobile" : "desktop"}_${(index)
    .toString()
    .padStart(5, "0")}.jpg`;
}

export function scaleToFill(img: HTMLImageElement, canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
  // get the scale
  var scale = Math.max(canvas.width / img.width, canvas.height / img.height);
  // get the top left position of the image
  var x = canvas.width / 2 - img.width / 2 * scale;
  var y = canvas.height / 2 - img.height / 2 * scale;
  context.drawImage(img, x, y, img.width * scale, img.height * scale);
}

// images[0].onload = render;

export function render(image: HTMLImageElement, canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
  context.clearRect(0, 0, canvas.width, canvas.height);
  scaleToFill(image, canvas, context);
}
