import * as React from "react";
import RadioButton from "./RadioButton";
import RangeSlider from "./RangeSlider";
import "./InputPanel.scss";
import { ModalContext } from "../../../components/modal/Modal";
import { Glossary, MajorDecisionsCopy } from "../logic/Messages";
import { OutlineButton } from "../../../components/OutlineButton";


interface IInputPanelProps {
    spacing: number;
    spacingChanged: (val: number) => void;
    depth: number;
    depthChanged: (val: number) => void;
    speed: number;
    speedChanged: (val: number) => void;
    decision: string;
    decisionChanged: (val: string) => void;
    round: number;
    prevRound: number[];
    onFinish: () => void;
}

export const InputPanel: React.FunctionComponent<IInputPanelProps> = (props) => {
  let { handleModal } = React.useContext(ModalContext);
  const prevSpeeds = props.prevRound.map(x => x.toString());
  const panelRef = React.createRef<HTMLDivElement>();

  React.useEffect(
    () => {
      if (panelRef.current)
        panelRef.current.scrollTo(0, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ props.round ]
  );


  return (<div className="InputPanel" ref={ panelRef }>
      <RangeSlider
        min={0}
        max={8}
        step={0.1}
        title={"Seed Spacing"}
        unit={"Inches"}
        value={props.spacing}
        onValueChange={(val) => props.spacingChanged(val)}
        onTitleClick={() => handleModal(Glossary.SeedSpacing)}
        locked={props.round === 3}
      />
      <RangeSlider
        min={0}
        max={4}
        step={0.1}
        title={"Seed Depth"}
        unit={"Inches"}
        value={props.depth}
        onValueChange={(val) => props.depthChanged(val)}
        onTitleClick={() => handleModal(Glossary.SeedDepth)}
        locked={props.round === 3}
      />
      <div className="control-title planting-speed" onClick={() => handleModal(Glossary.PlantingSpeed)}>Planting Speed</div>
      <RadioButton
        size="half"
        disabled={prevSpeeds.includes("1")}
        name="speed"
        value="1"
        label="1–3 mph"
        selected={props.speed.toString()}
        onChecked={() => props.speedChanged(1)}
        locked={props.round === 3 && props.speed.toString() === "1"}
        round={props.round}
      />

      <RadioButton
        size="half"
        disabled={prevSpeeds.includes("4")}
        name="speed"
        value="4"
        label="12–15 mph"
        selected={props.speed.toString()}
        onChecked={() => props.speedChanged(4)}
        locked={props.round === 3 && props.speed.toString() === "4"}
        round={props.round}
      />
      <RadioButton
        size="half"
        disabled={prevSpeeds.includes("2")}
        name="speed"
        value="2"
        label="4–7 mph"
        selected={props.speed.toString()}
        onChecked={() => props.speedChanged(2)}
        locked={props.round === 3 && props.speed.toString() === "2"}
        round={props.round}
      />

      <RadioButton
        size="half"
        disabled={prevSpeeds.includes("5")}
        name="speed"
        value="5"
        label="16–19 mph"
        selected={props.speed.toString()}
        onChecked={() => props.speedChanged(5)}
        locked={props.round === 3 && props.speed.toString() === "5"}
        round={props.round}
      />
      <RadioButton
        size="half"
        disabled={prevSpeeds.includes("3")}
        name="speed"
        value="3"
        label="8–11 mph"
        selected={props.speed.toString()}
        onChecked={() => props.speedChanged(3)}
        locked={props.round === 3 && props.speed.toString() === "3"}
        round={props.round}
      />
      <div className="clear"></div>
      {props.round === 2 && <>
        <div className="control-title major-decision" onClick={() => handleModal(Glossary.MajorDecisions)}>Major Decision:</div>
        <p>{MajorDecisionsCopy[props.round]}</p>
        <RadioButton name="decision" value="Early" label="Plant earlier than scheduled time" selected={props.decision} onChecked={() => props.decisionChanged("Early")} />
        <RadioButton name="decision" value="OnTime" label="Plant at scheduled time" selected={props.decision} onChecked={() => props.decisionChanged("OnTime")} />
        <RadioButton name="decision" value="Later" label="Plant later than scheduled time" selected={props.decision} onChecked={() => props.decisionChanged("Later")} />
      </>}

      {props.round === 3 && <>
        <div className="control-title major-decision" onClick={() => handleModal(Glossary.MajorDecisions)}>Major Decision:</div>
        <p>{MajorDecisionsCopy[props.round]}</p>
      </>}

      <OutlineButton className="yellow" text="See Your Harvest" onClick={props.onFinish} />
  </div>);
};
