import * as React from "react";
import "./VrNotSupported.scss";
import Edge from "./img/Edge.png";
import Chrome from "./img/Chrome.png";
import Firefox from "./img/Firefox.png";

interface IVrNotSupportedProps {}

export const VrNotSupported: React.FunctionComponent<IVrNotSupportedProps> = (props) => {
  return (
    <div className="VrNotSupported">
      <div>
        <h3>Improve Your Experience</h3>
        <p>
          This simulation is not supported in your browser. Please use Google Chrome, Firefox, or Microsoft Edge to run
          this 360° Experience.
        </p>
        <div className="Browsers">
          <div>
            <img src={Chrome} alt="" />
            <div>Google Chrome</div>
          </div>
          <div>
            <img src={Firefox} alt="" />
            <div>Firefox</div>
          </div>
          <div>
            <img src={Edge} alt="" />
            <div>Microsoft Edge</div>
          </div>
        </div>
      </div>
    </div>
  );
};
