import * as React from "react";
import { Header } from "../components/Header";

interface IPlaceholderPageProps {
}

const PlaceholderPage: React.FunctionComponent<IPlaceholderPageProps> = (props) => {
  return (<div className="PlaceholderPage" style={{ margin: "10%" }}>
      <Header/>
      WORK IN PROGRESS
  </div>);
};

export default PlaceholderPage;
