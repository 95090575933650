import * as React from "react";
import { ModalContext } from "../../components/modal/Modal";
import { OutlineButton } from "../../components/OutlineButton";
import { InputPanel } from "./components/InputPanel";
import { ResultsIntro } from "./components/ResultsIntro";
import { ResultsDetail } from "./components/ResultsDetail";
import "./Game.scss";
import { CalculateScore, MajorDecisionChoice } from "./logic/GameCalculator";
import { GameOver } from "./components/GameOver";
import { RoundIntroModal, RoundIntroModalButton } from "./logic/Messages";
import { SeedContainer } from "./components/SeedContainer";
import { Header } from "../../components/Header";

interface IGameTestProps {}

const GameTest: React.FunctionComponent<IGameTestProps> = (props) => {
  const [ seedDepth, setSeedDepth ] = React.useState(0);
  const [ seedSpacing, setSeedSpacing ] = React.useState(0);
  const [ speed, setSpeed ] = React.useState(0);
  const [ round, setRound ] = React.useState(1);
  const { handleModal, setModal } = React.useContext(ModalContext);
  const [ showingResultsIntro, setShowingResultsIntro ] = React.useState(false);
  const [ showingResultsDetail, setShowingResultsDetail ] = React.useState(false);
  const [ gameOver, setGameOver ] = React.useState(false);
  const [ decision, setDecision ] = React.useState<MajorDecisionChoice>("");
  const [ showHumanError, setShowHumanError ] = React.useState(false);
  const [ previousRoundChoices, setPreviousRoundChoices ] = React.useState<number[]>([speed]);

  function showModal(nextRound: number) {
    handleModal({
      title: "",
      definition: (<>
        {RoundIntroModal[nextRound]}
        <OutlineButton text={RoundIntroModalButton[nextRound]} onClick={() => setModal(false)} />
        </>
      ),
      align: "center",
      suppressCloseButton: true
    });
  }

  React.useEffect(() => {
    showModal(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function showResults() {
    if(!checkFields(round))
      return;
    setShowingResultsIntro(true);
  }

  function showResultsDetail() {
    setShowingResultsDetail(true);
    setShowingResultsIntro(false);
  }

  function nextRound() {
    if (round === 3) {
      setGameOver(true);
    } else if (round === 2){
      if (!showHumanError) {
        setShowHumanError(true);
        return;
      }

      setSpeed(3);
      setSeedDepth(2);
      setSeedSpacing(6);
      showModal(round + 1);

    } else {
      showModal(round + 1);
      setSpeed(0);
    }
    setShowHumanError(false);
    setRound(round + 1);
    setPreviousRoundChoices([...previousRoundChoices, speed]);

    setShowingResultsIntro(false);
    setShowingResultsDetail(false);
  }

  function checkFields(currentRound: number) {
    const errors: string[] = [];

    if (seedSpacing === 0) {
      errors.push("Please set your seed spacing.");
    }
    if (seedDepth === 0) {
      errors.push("Please set your seed depth.");
    }
    if (speed === 0) {
      errors.push("Please select a planting speed.");
    }
    if (currentRound === 2 && !decision) {
      errors.push("Please select your major decision.");
    }

    if (errors.length) {
      handleModal({
        title: "Answers Required",
        definition: <div>{errors.map(x => <p>{x}</p>)}</div>,
        align: "center"
      });
      return false;
    }

    return true;
  }

  const score = CalculateScore(seedDepth, seedSpacing, speed.toString(), decision);

  if (showHumanError) {
    score.spacing = Math.max(score.spacing - 1, 1);
    score.speed = Math.max(score.spacing - 1, 1);
    score.depth = Math.max(score.spacing - 1, 1);
    score.percentage *= 0.8;
    score.bushels *= 0.8;
  }

  return (
    <div className="Game">
      <Header />
      <InputPanel
        spacing={seedSpacing}
        spacingChanged={(val) => setSeedSpacing(val)}
        depth={seedDepth}
        depthChanged={(val) => setSeedDepth(val)}
        speed={speed}
        speedChanged={(val) => setSpeed(val)}
        decision={decision}
        decisionChanged={(val) => setDecision(val as MajorDecisionChoice)}
        round={round}
        prevRound={previousRoundChoices}
        onFinish={() => showResults()}
      />
      {showingResultsIntro && <ResultsIntro round={round} onDismiss={showResultsDetail} score={score} />}
      {!showHumanError && showingResultsDetail && <ResultsDetail round={round} onDismiss={nextRound} score={score} humanErrorRound={showHumanError}/>}
      {showHumanError && showingResultsDetail && <ResultsDetail round={round} onDismiss={nextRound} score={score} humanErrorRound={showHumanError}/>}
      {gameOver && <GameOver />}
      <SeedContainer spacing={seedSpacing} depth={seedDepth} />
    </div>
  );
};

export default GameTest;
