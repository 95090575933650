import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useLocation, withRouter } from "react-router-dom";

interface IScrollToTopProps {}

const _ScrollToTop: React.FunctionComponent<IScrollToTopProps> = (props) => {
  const { pathname } = useLocation();
  React.useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    [ pathname ]
  );
  return <div>{props.children}</div>;
};

const ScrollToTop = withRouter(_ScrollToTop);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// eslint-disable-next-line no-restricted-globals
history.scrollRestoration = "manual"