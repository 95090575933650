import { ModalContent } from "../../../components/modal/Modal";
import { ResultsScore } from "./GameCalculator";

export const MsgSeedDepth = [
  "Every seed being planted at the exact same depth keeps the seeds protected during their most vulnerable period of growth while making emergence easier and more successful.",
  "We’ve seen light breezes move more soil. Try planting a bit deeper.",
  "Digging a slow hole to the center of the Earth? You’re down way too far.",
  "You’re close, but not close enough. Try going down just a little bit deeper.",
  "You went just a bit too far. Pop a little soil back in and try again.",
  "Perfect! Are you a robot, or just a good farmer?",
];

export const MsgSeedSpacing = [
  "Our technology spaces seed at consistent intervals every time, allowing for both the above-and below-ground parts of the plant to have room for growth and sun without sacrificing space in the field.",
  "Are these seeds glued together? Get them separated and spaced way farther apart.",
  "Keep your friends close, your enemies closer, and your seeds that much farther apart.",
  "With the seeds that far apart, you’re wasting land and money. Get them closer together and you’ll reap the benefits.",
  "Like a high school dance chaperone, we’re telling you to make a little more space between.",
  "The length of a dollar, and the perfect distance for seed spacing."
];

export const MsgPlantingSpeed = [
  "John Deere Precision Ag Technology can plant seeds with equivalent precision at up to 10 mph in varying conditions, doubling or more than doubling the speed at which seeds have typically been planted for the last 70+ years. This leaves the farmer with less to worry about and more time on his side when it comes to fighting the seasonal cropping clock.",
  "In a hurry? Slow it way down.",
  "You're planting just fine, but it’s going to be harvest time before you’re done. Pick up the pace!",
  "We’re excited about planting, too, but you need to slow it down a notch.",
  "You've got a little pep in your step, but you could still plant faster and be just as accurate.",
  "Planting at 10 mph is the perfect combination of speed and accuracy.",
];

export const MsgMajorDecision = {
  "": "",
  Intro:
    "As mentioned, these are just two seeds out of millions that will get planted by a single farmer. Because you chose to plant",
  Early:
    "earlier than your scheduled time, the heavy rains took advantage of your inconsistent cropping and ruined a portion of your harvest, as reflected in your score.",
  OnTime:
    "at your scheduled time, the heavy rains took advantage of your inconsistent cropping and ruined a portion of your harvest, as reflected in your score.",
  Later:
    "later than your scheduled time, you missed that prime window of cropping opportunity, as reflected in your score.",
  Outro:
    "If planting with machinery not equipped with the latest tech, there’s a human error to account for. So let’s account for it."
};

export const ResultsIntroMessage = {
  Average: (
    <p>
      If every farmer in the country produced this yield, the country would be short of the overall yield expected for
      production of necessary goods and products. Lowered profits from this yield would also compromise each farmer’s
      financial security.
    </p>
  ),
  "Above Average": (
    <p>
      If every farmer in the country produced this yield, the country would be ahead of the overall yield expected for
      production of necessary goods and products. Above-average profits from this yield would also stabilize each
      farmer’s financial security.
    </p>
  ),
  Suffering: (
    <p>
      If every farmer in the country produced this yield, the country would be short of the overall yield expected for
      production of necessary goods and products. Lowered profits from this yield would also compromise each farmer’s
      financial security.
    </p>
  )
};

export const ResultsOutroMessage = (round: number, score: ResultsScore) => [
  <p>
    Your score went down by 15-20 percent, a reasonable margin of error when considering all the variables of a single planting season. Try one last time using John Deere Automated Precision Ag Technology.
  </p>,
  <p>
    Review the tips from your first harvest and try again. Be careful, though, as things aren’t quite what they seem.
  </p>,
  <p>
    {`${MsgMajorDecision.Intro} ${MsgMajorDecision[score.decision]} ${MsgMajorDecision.Outro}`}
  </p>,
  <p>
    You’ve done it! (This time.)
  </p>,
][round];

export const Glossary: { [key: string]: ModalContent } = {
  SeedSpacing: {
    title: "Seed Spacing",
    definition: (
      <p>
        Seed spacing is a measurement of the distance from one seed to another in a row. Rows themselves are typically
        spaced 30 inches apart.
      </p>
    )
  },
  SeedDepth: {
    title: "Seed Depth",
    definition: (
      <p>
        Seed depth is a measurement of how deep the seed is planted in the soil. The top layer of soil itself is typically
        10 inches deep.
      </p>
    )
  },
  PlantingSpeed: {
    title: "Planting Speed",
    definition: (
      <p>
        Planting speed is the rate at which the tractor and planter move when placing the seeds into the ground. Too
        slow and time is wasted; too fast and the seeds are planted inconsistently.
      </p>
    )
  },
  MajorDecisions: {
    title: "Major Decisions",
    definition: (
      <p>
        Each planting season, a farmer makes over 140 major decisions revolving around seed selection and health,
        financial limitations, soil care and treatment, equipment choices, and more.
      </p>
    )
  },
  Acre: {
    title: "Acre",
    definition: (
      <p>
        The average corn farmer plants around 33,500 seeds per acre, while the average soybean farmer plants around
        140,000 seeds per acre. Though the traditional ways of measuring an acre of land include the dimensions of one
        chain by one furlong, or the area that could be plowed in one day by a yoke of oxen, we think it’s easier to
        imagine a space just shy of an American football field or half a city block.
      </p>
    )
  },
  YieldSize: {
    title: "Yield Size",
    definition: (
      <p>
        In agriculture, the yield is a measurement of the amount of a crop grown and harvested in a specific area of
        land. By reducing and managing inputs in a sustainable way, farmers can generally maximize their yields;
        increase their profits; and food, clothe, and shelter the world.
      </p>
    )
  },
  Bushel: {
    title: "Bushel",
    definition: (
      <p>
        A bushel is a unit of measurement in farming. There’s a lot of math that gets the farmer close to an estimate of
        how many bushels to expect per acre, but just know for our purposes that your average bushel of corn is about 56
        pounds."
      </p>
    )
  },
  CornByProducts: {
    title: "Corn By-Products",
    definition: (
      <div>
        <p>
          <strong>Food products:</strong> Cereals, snack foods, salad dressings, soft drink sweeteners, chewing gum,
          peanut butter, hominy grits, taco shells, and specialty corn including white corn, blue corn, and popcorn.
        </p>

        <p>
          <strong>Animal feeds:</strong> Dried distiller’s grain, gluten feed and meal, and high-oil feed corn for cattle,
          swine, poultry, and fish.
        </p>

        <p>
          <strong>Industrial products:</strong> Soaps, paints, corks, linoleum, polish, adhesives, rubber substitutes,
          wallboard, dry-cell batteries, textile finishings, cosmetic powders, candles, dyes, pharmaceuticals,
          lubricants, insulation, wallpaper, and other starch products.
        </p>

        <p>
          <strong>Fermentation products and by-products:</strong> Industrial alcohols, fuel ethanol, recyclable
          plastics, industrial enzymes, fuel octane enhancers, fuel oxygenates, and solvents.
        </p>

        <p className="small">
          Source: U.S. Department of Agriculture, National Corn Growers Association
        </p>
      </div>
    )
  }
};

export const MajorDecisionsCopy = [
  "",
  "",
  "The forecast calls for heavy rains the week after you were planning to plant. What should you do?",
  "While there are still many major decisions you’ll have to make with each season, they’ve been significantly eased by the precision of ExactEmerge™ automation technology neutralizing the above three variables."
]

export const NextRoundButtonTitle = [ "Take Control", "Plant Again", "Account for Human Error", "Succeed Along Nature" ];

export const RoundIntroModal = [
  <p />,
  <p>
    Maximize your yield by setting your planting variables in the optimal position. Though you’re adjusting just two
    seeds, this is multiplied by the millions of seeds planted by each farmer every growing season. Balance wisely.
  </p>,
  <p>
    With some pointers for a higher yield this year, you’re preparing your second planting season. This time, though,
    you’ll have to factor in one of the many major decisions a farmer makes along with seed spacing, depth, and planting
    speed. Are you ready?
  </p>,
  <p>
    <span className="green">John Deere Automated Precision Ag Technology</span> removes human error with GPS, using
    sensors and electrification to make sure you never plant a seed of doubt.
  </p>
];

export const RoundIntroModalButton = [ "", "I’m Ready", "I’m Ready", "Stop Guessing" ];
