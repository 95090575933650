import * as React from "react";
import { Header } from "../../../components/Header";
import { OutlineButton } from "../../../components/OutlineButton";
import { ColorForScore, RatingForScore, ResultsScore } from "../logic/GameCalculator";
import { ResultsIntroMessage } from "../logic/Messages";
import "./ResultsIntro.scss";

interface IResultsIntroProps {
  score: ResultsScore;
  round: number;
  onDismiss: () => void;
}

export const ResultsIntro: React.FunctionComponent<IResultsIntroProps> = (props) => {
  const rating = RatingForScore(props.score);

  return (
    <div className="ResultsIntro">
      <Header />
      <div className="Container">
        <h1>
          Your Yield and Profits are <span className={ColorForScore(props.score)}>{rating}.</span>
        </h1>
        {ResultsIntroMessage[rating]}
        <OutlineButton className="yellow" onClick={props.onDismiss} text={"Show My Stats"} />
      </div>
    </div>
  );
};
