import classNames from "classnames";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import logo from "../img/Logo.png";
import "./Header.scss";
import { AnchorOutlineButton } from "./OutlineButton";
interface IAppMenuProps {}

const AppMenu: React.FunctionComponent<IAppMenuProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  return (
    <div className="AppMenu">
      <div className={classNames("menu-toggle", { open })} role="button" onClick={() => setOpen(!open)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={classNames("menu", { open })}>
        <li className={location.pathname === "/" ? "active home" : "home" }>
          <Link to="/">Home</Link>
        </li>
        {!isMobile && <li className={location.pathname === "/vr" ? "active" : "" }>
          <Link to="/vr">360° Experience</Link>
        </li>}
        <li className={location.pathname.startsWith("/game") ? "active" : "" }>
          <Link to="/game">Test Your Precision</Link>
        </li>
        <li className={location.pathname === "/facts" ? "active" : "" }>
          <Link to="/facts">Fact Sheet</Link>
        </li>
        <li className={location.pathname === "/experts" ? "active" : "" }>
          <Link to="/experts">Meet The Experts</Link>
        </li>
        <li className={location.pathname === "/media" ? "active" : "" }>
          <Link to="/media">Resources</Link>
        </li>
        <li>
          <a className="OutlineButton header active" href="https://www.ces.tech/2021-exhibitor-microsite.aspx" target="_blank" rel="noreferrer">EXPLORE CES<sup>®</sup> 2021 <span>→</span></a>
        </li>
      </ul>
    </div>
  );
};

export default AppMenu;

interface IHeaderProps {}

export const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  return (
    <header>
      <Link to="/"><img src={logo} alt="" /></Link>
      <AppMenu />
    </header>
  );
};
