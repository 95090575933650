import * as React from "react";
import "./RangeSlider.scss";
import { ReactComponent as Lock } from "../img/Lock.svg";

interface IRangeSliderProps {
    min: number;
    max: number;
    step: number;
    title: string;
    unit: string;
    value: number;
    onValueChange: (val: number) => void;
    onTitleClick: () => void;
    locked: boolean;
}

const RangeSlider: React.FunctionComponent<IRangeSliderProps> = (props) => {
  return (<div className="RangeSlider">
      <div className="range-header">
        <div className="control-title" onClick={props.onTitleClick}>{props.title}:</div>
        <div className="range-value">
            <span>{props.unit}</span>
            <div className="value-display">{props.value}</div>
        </div>
      </div>
      <input className="slider" type="range" onChange={e => props.onValueChange(Number(e.target.value))} {...props} disabled={props.locked}/>
      {props.locked && <Lock className="lock-icon"/>}
    </div>);
};

export default RangeSlider;
