import * as React from "react";
import { Header } from "../../components/Header";
import "./FactSheetPage.scss";
import { ReactComponent as Person } from "./img/CES-FactSheet-Icon_Person.svg";
import { ReactComponent as Corn } from "./img/CES-FactSheet-Icon_Corn.svg";
import { ReactComponent as Wheat } from "./img/CES-FactSheet-Icon_Wheat.svg";
import { ReactComponent as Topo } from "./img/CES-FactSheet-Icon_Topography.svg";
import { ReactComponent as Measurement } from "./img/CES-FactSheet-Icon_Measurement.svg";
import { ReactComponent as FarmLand } from "./img/CES-FactSheet-Icon_FarmLand.svg";
import { ReactComponent as Plus } from "./img/CES-FactSheet-Icon_Plus.svg";
import { ReactComponent as Satellite } from "./img/CES-FactSheet-Icon_Satellite.svg";
import { ReactComponent as GPS } from "./img/CES-FactSheet-Icon_GPS.svg";
import { ReactComponent as Calendar } from "./img/CES-FactSheet-Icon_Calendar.svg";
import { ReactComponent as Target } from "./img/CES-FactSheet-Icon_Target.svg";
import { ReactComponent as Tech } from "./img/CES-FactSheet-Icon_Tech.svg";
import Footer from "../../components/Footer";
import { AnchorOutlineButton } from "../../components/OutlineButton";

interface IFactSheetPageProps {}

export const FactSheetPage: React.FunctionComponent<IFactSheetPageProps> = (props) => {
  return (
    <div className="FactSheetPage">
      <Header />
      <div className="banner">Quick Facts</div>
      <div className="sub-banner" />

      <div className="parent">
        <div className="div1">
          <div>
            <h3><span>GROWING MORE FOR</span> <span>A GROWING PLANET</span></h3>
            <div className="flex">
              <div>
                <div className="icon">
                  <Person />
                  <Person />
                  <Person />
                  <Person />
                  <Person />
                  <Person />
                  <Person />
                </div>
                <p>
                  The world’s population is expected to grow to nearly <strong className="nobr">10 billion by 2050,</strong> increasing
                  global food demand by <strong>50%.</strong>
                </p>
                {/* <div className="footnote">(World Population Prospects 2019: Highlights, United Nations)</div> */}
              </div>
              <div>
                <div className="icon">
                  <Corn />
                  <Wheat />
                  <Corn />
                  <Wheat />
                  <Corn />
                  <Wheat />
                  <Corn />
                </div>
                <p>
                  To reduce hunger and serve a growing population, we need to increase agricultural productivity by{" "}
                  <strong className="nobr">60 to 70%.</strong>
                </p>
                {/* <div className="footnote">(Food and Agriculture Organization of the United Nations)</div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="div2">
          <div>
            <h3>WORLDWIDE REACH</h3>
            <div className="icon-flex">
              <div>
                <Topo />
              </div>
              <p>
                Almost <strong>230 million acres</strong> across <strong>100+ countries</strong> around the world (25% of
                global arable land) were covered by <span className="nobr">John Deere</span> technology in 2020 — a 53% increase since August 2019. This
                is over <strong>975 times</strong> the total land area of New York City.
              </p>
            </div>
          </div>
        </div>
        <div className="div3">
          <div>
            <h3>HOW BIG IS A <span className="nobr">JOHN DEERE</span> FARM?</h3>
            <div className="icon-flex">
              <div>
                <Measurement />
              </div>
              <p>
                A 5,000 acre farm using <strong><span className="nobr">John Deere</span> Precision Ag Technology</strong> equates to roughly 4,000 football fields.
              </p>
            </div>
          </div>
        </div>
        <div className="div4 single">
          <div>
            <h3 className="mobile-only">GROWING OPPORTUNITIES</h3>
            <div className="icon-flex">
              <div>
                <FarmLand />
              </div>
              <div>
                <h3>GROWING OPPORTUNITIES</h3>
                <p>
                  With just one planting season per crop per year, farmers only have <strong>approximately 40 chances</strong> for each seed to reach its maximum yield potential.  Optimal planting windows average just <strong className="nobr">10 days</strong>, so variables such as a stretch of rainy days could delay planting and lead to a <strong><span className="nobr">1% reduction</span> per day in yield</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="div5">
          <div>
            <h3>PLANT POPULATION</h3>
            <div className="icon-flex">
              <div>
                <Plus />
              </div>
              <p>
                An average farm can have more than <strong className="nobr">350 million</strong> plants that must rely on{" "}
                <strong>data-driven insights and technology</strong> to operate at a tremendous scale to best address each
                seed’s needs.
              </p>
            </div>
          </div>
        </div>
        <div className="div6">
          <div>
            <h3>MAPPING THE FIELD</h3>
            <div className="icon-flex">
              <div>
                <Satellite />
              </div>
              <p>
                All large <span className="nobr">John Deere</span> machines include built-in GPS, enabling the accuracy of cameras, sensors, and
                equipment to <strong className="nobr">2.5 cm (&lt; 1 inch).</strong> This is extremely precise in comparison with a typical
                GPS-enabled smartphone that is accurate to within a <strong>4.9-meter (16 feet) radius</strong> under open
                sky.
                {/* <span className="small"><em>(Institute of Navigation)</em></span> */}
              </p>

            </div>
          </div>
        </div>
        <div className="div7 single">
          <div>
            <h3 className="mobile-only">STEERED IN THE RIGHT DIRECTION</h3>
            <div className="icon-flex">
              <div>
                <GPS />
              </div>
              <div>
                <h3>STEERED IN THE RIGHT DIRECTION</h3>
                <p>
                  <span className="nobr">John Deere</span> tractors have been able to{" "}
                  <strong>
                    self-steer, precisely place seeds, and develop accurate geospatial data insights for
                    nearly 20 years
                  </strong>. A single <span className="nobr">John Deere</span> tractor and planter have the ability to precisely plant over{" "}
                  <strong>700 corn seeds </strong>
                  and <strong>2,800 soybean seeds every second.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="div8">
          <div>
            <h3>DEERE THROUGH THE DECADES</h3>
            <div className="icon-flex">
              <div>
                <Calendar />
              </div>
              <p>
                In 1950, the two-row <span className="nobr">John Deere</span> Model 290 Planter is the first “precision” planting system that can plant
                <strong> 30 acres per day</strong>. Through <strong> 70 years </strong> of technology advancement, today’s
                24-row planter can plant over <strong>45 acres per hour</strong>{" "}
                <em>(that’s up to 540 acres planted in a 12-hour day)!</em>
              </p>
            </div>
          </div>
        </div>
        <div className="div9">
          <div>
            <h3>PINPOINT SUSTAINABILITY</h3>
            <div className="icon-flex">
              <div>
                <Target />
              </div>
              <p>
                Increased precision and autonomy during planting can increase yields and sustainability. Considering that
                just one acre of land averages <strong>37 bushels of wheat</strong>, or{" "}
                <strong>3,330 loaves of bread,</strong> getting the most out of every seed is critical.
              </p>
            </div>
          </div>
        </div>
        <div className="div10 single">
          <div>
            <h3 className="mobile-only">MILLIONS OF MEASUREMENTS</h3>
            <div className="icon-flex">
              <div>
                <Tech />
              </div>
              <div>
                <h3>MILLIONS OF MEASUREMENTS</h3>
                <p>
                  The highly automated 8RX Tractor and a 60-foot, 24-row planter are powered by over{" "}
                  <strong>300 sensors</strong> and <strong>140 controllers</strong> — processing{" "}
                  <strong>15,000 measurements per second per machine.</strong> On a typical day of spring planting, John
                  Deere processes <strong>five to 15 million sensor measurements every second</strong>{" "}
                  <em>
                    (at eight bytes per measurement, this is upwards of 100 megabytes per second into the Deere data
                    platform)
                  </em>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="download-link">
        <AnchorOutlineButton className="yellow" text="Download Fact Sheet" to={"http://jd-ces-2021.s3-website-us-east-1.amazonaws.com/media-resources/docs/CES-FactSheet_Print.pdf"}/>
      </div>
      <Footer />
    </div>
  );
};
