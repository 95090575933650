import * as React from "react";

import "./HomePage.scss";
import { useWindowSize } from "../../util/use-window-resize";
import { currentFrame, render } from "./Util";
import classNames from "classnames";
import { Header } from "../../components/Header";
import { GamePanel } from "./GamePanel";
import { JoinUsPanel } from "./JoinUsPanel";
import { TeaserPanel } from "./TeaserPanel";
import { ProgramSchedule } from "./ProgramSchedule";
import { HypeVideo } from "./HypeVideo";
import { VrExperience } from "./VRExperience";
import Footer from "../../components/Footer";
import { ModalContext } from "../../components/modal/Modal";
import CTO from "./img/Jahmy-Photo_Letter-Vert.jpg";
import CTO_MOBILE from "./img/Jahmy-Photo_Letter-Horz.jpg";
import SIGNATURE from "./img/Jahmy-Signature.jpg";
import { ScrollIcon } from "../../components/ScrollIcon";
import { ScrollIconMobile } from "../../components/ScrollIconMobile";

const ctoMessage = (
  <div>
    <h2>WELCOME TO CES<sup>®</sup> 2021</h2>
    <p>
      In a normal year, John Deere would welcome you to our booth at the Las Vegas Convention Center, where you’d have
      the opportunity to climb into the cab of a tractor, check out all the sensors on a planter up close, and see
      demos of the broad range of technology farmers are leveraging today — like GPS and artificial intelligence. From
      the ground up, the combination of these advanced technologies and science-driven insights provide farmers with
      the tools needed to unlock the potential of every seed.
    </p>
    <p>
      Instead, we want to bring you to the farm virtually — to experience what farmers do when they plant millions of
      seeds in the ground to grow the food, fuel, and fiber that power our world — and impact every single one of us.
    </p>
    <p>Sincerely,</p>
    <img src={SIGNATURE} alt=""/>
    <p><strong>Jahmy Hindman</strong> <br /> Chief Technology Officer</p>
  </div>

);


interface IHomePageProps {}

export const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {
  const wasLoaded = Boolean(sessionStorage.getItem("home-page-loaded"));
  const [animationComplete, setAnimationComplete] = React.useState(wasLoaded ? true : false);
  const [showTeaser, setShowTeaser] = React.useState(wasLoaded ? false : true);
  const canvasRef = React.createRef<HTMLCanvasElement>();
  const windowSize = useWindowSize();
  const frameCount = 94;
  const onMobile = windowSize.height > windowSize.width;
  const { handleModal } = React.useContext(ModalContext);
  let [ remaining, decrementRemaining ] = React.useReducer((state) => {
    return state - 1;
  }, frameCount);
  //   let [ progress, setProgress ] = React.useState(0);
  const images: HTMLImageElement[] = React.useMemo(() => [] as HTMLImageElement[], []);

  const airpods = React.useMemo<{ frame: number }>(
    () => ({
      frame: 0
    }),
    []
  );

  React.useEffect(
    () => {
      if (canvasRef.current) {
        // console.log(`setting size: ${windowSize.width} x ${windowSize.height}`);
        canvasRef.current.width = windowSize.width;
        canvasRef.current.height = onMobile ? windowSize.width * 1.7 : windowSize.height * 1.2;
        if (images.length) {
          render(images[wasLoaded ? frameCount - 1 : airpods.frame], canvasRef.current!, canvasRef.current.getContext(
            "2d"
          ) as CanvasRenderingContext2D);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ airpods.frame, canvasRef, images, windowSize ]
  );

  React.useEffect(() => {
    if (!windowSize.height) return;
    if (images.length) return;
    for (let i = 0; i < frameCount; i++) {
      const img = new Image();
      img.src = currentFrame(i, windowSize.height > windowSize.width);
      img.onload = () => {
        decrementRemaining();
      };
      images.push(img);
    }
  });

  React.useEffect(() => {
    document.body.classList.toggle("showing-teaser", !animationComplete);

    return () => document.body.classList.remove("showing-teaser");
  }, [animationComplete])

  React.useEffect(() => {
    if (!showTeaser) {
      const interval = setInterval(() => {
        if (airpods.frame === frameCount - 1) {
          clearInterval(interval);
          setTimeout(() => {
            setAnimationComplete(true);
            sessionStorage.setItem("home-page-loaded", "true");
            handleModal({
              title: "",
              definition: ctoMessage,
              img: onMobile ? CTO_MOBILE : CTO,
              className: "left-heading cto"
            });
          }, 2000);
          return;
        }
        canvasRef.current &&
        render(images[airpods.frame++], canvasRef.current!, canvasRef.current.getContext(
          "2d"
        ) as CanvasRenderingContext2D)
      }, 100) // use animation onUpdate instead of scrollTrigger's onUpdate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTeaser])

  return (
    <div className={classNames("HomePage", { loaded: frameCount - remaining + 6 === 100, onMobile, animationComplete })}>
      <Header />
      <div className="loader">
        <div className="loader-dialog">
          <div>Loading...</div>
          <div className="loader-container">
            <div className="loader-bar" style={{ width: `${frameCount - remaining + 6}%` }} />
          </div>
        </div>
      </div>
      <div className="canvas-placeholder">
        <canvas id="canvas-hero" ref={canvasRef} />
        <ScrollIcon />
        <ScrollIconMobile />
      </div>
      {showTeaser && <TeaserPanel onLaunch={() => setShowTeaser(false)} windowHeight={windowSize.height} onMobile={onMobile}/>}
      <HypeVideo onMobile={onMobile} />
      <ProgramSchedule />
      <VrExperience />
      <GamePanel />
      <JoinUsPanel />
      <Footer />
    </div>
  );
};
