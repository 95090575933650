import * as React from "react";
import { LinkOutlineButton } from "../../../components/OutlineButton";
import "./GameOver.scss";

interface GameOverProps {}

export const GameOver: React.FunctionComponent<GameOverProps> = (props) => {
  return (
    <div className="GameOver">
      <div>
        <p>
          Thankfully, John Deere Automated Precision Ag Technology turns “this time” into “every time” with the
          advantage of precision. Build the future...
        </p>
        <div className="outline-text">From the ground up.</div>
        <LinkOutlineButton text="Return to Home Page" to="/" />
      </div>
    </div>
  );
};
