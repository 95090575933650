/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { OutlineButton, LinkOutlineButton } from "../../../components/OutlineButton";
import "./GameIntro.scss";
import { ModalContext } from "../../../components/modal/Modal";
import { Glossary } from "../logic/Messages";
import { Header } from "../../../components/Header";
import classNames from "classnames";

interface IGameIntroDotsProps {
  active: number;
  onClick: (clicked: number) => void;
}

const GameIntroDots: React.FunctionComponent<IGameIntroDotsProps> = (props) => {
  console.log(props);
  const vals = [0,1,2];

  console.log(vals);
  return (
    <ul className="BrainhubCarousel__dots">
      {vals.map((i) => (
        <li>
          <div
            className={classNames("BrainhubCarousel__dot", { "BrainhubCarousel__dot--selected": props.active === i })}
            role="button"
            key={`dot-${i}`}
            onClick={() => props.onClick(i)}
          >
            {i}
          </div>
        </li>
      ))}
    </ul>
  );
};

const GameIntroTitle = (props: { onClick: () => void }) => (
  <div className="GameIntroTitle">
    <div>
      <h2>How Precise Are Your Planting Skills?</h2>
      <OutlineButton text="Play the Game" onClick={() => props.onClick()} />
    </div>
  </div>
);

interface IGameIntroCarouselProps {
}
const GameIntroCarousel: React.FunctionComponent<IGameIntroCarouselProps> = (props) => {
  const [ slide, setSlide ] = React.useState(0);
  const { handleModal } = React.useContext(ModalContext);

  function moveStep(val: number) {
    console.log(`moving: ${val}`);
    setSlide(val);
  }
  return (
    <div className="GameIntroCarousel">
      <div className={classNames("GameIntroSlide", {active: slide === 0})} data-step={0}>
        <div>
          <h2>MAXIMIZE THE FUTURE</h2>
          <p>
            The days of the overalls-clad farmer throwing seed after seed from a big burlap sack are long gone. The
            progress of agricultural automation has eliminated the guesswork and brought us to the present: each seed at
            the exact depth and spacing it needs to be in, every time.
          </p>
          <div className="arrow-right" onClick={() => moveStep(1)}></div>
          <GameIntroDots active={slide} onClick={(val) => moveStep(val)} />
        </div>
      </div>
      <div className={classNames("GameIntroSlide", {active: slide === 1})} data-step={1}>
        <div>
          <h2>OUR HOMEGROWN WORLD</h2>
          <p>
            A single Iowa corn farmer may work from 500 to over 5,000{" "}
            <a href="#" onClick={() => handleModal(Glossary.Acre)}>
              acres
            </a>. American corn must be harvested in high{" "}
            <a href="#" onClick={() => handleModal(Glossary.YieldSize)}>
              yields
            </a>{" "}
            not just to create sustainable profits for the farmer, but to be shipped around the world and used in many{" "}
            <a href="#" onClick={() => handleModal(Glossary.CornByProducts)}>
              by-products
            </a>, including other foods, fuels, cosmetics, and batteries.
          </p>
          <div className="arrow-left" onClick={() => moveStep(0)}></div>
          <div className="arrow-right" onClick={() => moveStep(2)}></div>
          <GameIntroDots active={slide} onClick={(val) => moveStep(val)} />
        </div>
      </div>
      <div className={classNames("GameIntroSlide", {active: slide === 2})} data-step={2}>
        <div>
          <h2>AUTOMATIC ACCURACY</h2>
          <p>
            Without automation technology utilizing GPS, robotics, sensors, and connectivity, unpredictability spreads
            from the field to the market. We’re talking corn, but this applies to everything from cotton seed to canola
            seed, meaning the future is literally in your hands. <br />
            <br />
            So, go ahead and try planting without automation — it’s only everything at stake.
          </p>
          <LinkOutlineButton text="Start Planting" to="/game/play" />
          <div className="arrow-left" onClick={() => moveStep(1)}></div>
          <GameIntroDots active={slide} onClick={(val) => moveStep(val)} />
        </div>
      </div>
    </div>
  );
};

interface IGameIntroProps {
  showIntro?: boolean;
}
const GameIntro: React.FunctionComponent<IGameIntroProps> = (props) => {
  const [ step, setStep ] = React.useState(0);
  const [ showTitle, setShowTitle ] = React.useState(props.showIntro === false ? false : true);

  return (
    <div className="GameIntro" data-step={step}>
      <Header />
      {showTitle && (
        <GameIntroTitle
          onClick={() => {
            setShowTitle(false);
            setStep(1);
          }}
        />
      )}
      <GameIntroCarousel />
    </div>
  );
};

export default GameIntro;
