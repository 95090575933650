/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import fb from "../img/social/Facebook.png"
import insta from "../img/social/Instagram.png"
import twitter from "../img/social/Twitter.png"
import yt from "../img/social/YouTube.png"
import "./Footer.scss";

interface IFooterProps {
}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  return (<div className="footer">
  <div className="left">
    Copyright© 2021 Deere &amp; Company. All Rights Reserved
  </div>
  <div className="right">
    <a href="https://www.facebook.com/JohnDeereUSCA" target="_blank" rel='noreferrer'><img src={fb} alt=""/></a>
    <a href="https://www.instagram.com/johndeere/?hl=en" target="_blank" rel='noreferrer'><img src={insta} alt=""/></a>
    <a href="https://twitter.com/JohnDeere" target="_blank" rel='noreferrer'><img src={twitter} alt=""/></a>
    <a href="https://www.youtube.com/user/JohnDeere" target="_blank" rel='noreferrer'><img src={yt} alt=""/></a>
  </div>
</div>);
};

export default Footer;
