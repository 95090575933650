import classNames from "classnames";
import * as React from "react";
import { ModalContext } from "../../components/modal/Modal";
import "./HypeVideo.scss";
import { ReactComponent as Playhead } from "./img/Playhead.svg";
import ThumbTwoSeeds from "./img/TaleofTwoSeeds-Mobile.jpg";
import ThumbFuture from "./img/TheFutureIsExact-Mobile.jpg";

interface IHypeVideoCellProps {
  className: string;
  clip: string;
  title: string;
  video: string;
  thumb: string;
}

const HypeVideoCell: React.FunctionComponent<IHypeVideoCellProps> = (props) => {
  const vidRef = React.createRef<HTMLVideoElement>();
  const { handleModal } = React.useContext(ModalContext);

  const playVideo = () => {
    if (vidRef.current) vidRef.current.play();
  };

  const pauseVideo = () => {
    if (vidRef.current) vidRef.current.pause();
  };
  return (
    <div className={classNames("HypeVideoCell", props.className)} onMouseEnter={playVideo} onMouseLeave={pauseVideo}>
      <video src={props.clip} ref={vidRef} muted={true} loop={true} />
      <Playhead
        className="Playhead"
        onClick={() =>
          handleModal({
            lightbox: true,
            video: props.video,
            suppressDownload: true
          })}
      />
      <div className="bg" />
      <div className="text">
        <h2>{props.title}</h2>
      </div>
    </div>
  );
};

const HypeVideoCellMobile: React.FunctionComponent<IHypeVideoCellProps> = (props) => {
  const vidRef = React.createRef<HTMLVideoElement>();
  const [playing, setPlaying] = React.useState(false);

  const playVideo = () => {
    if (vidRef.current) {
      vidRef.current.play();
      setPlaying(true);
    }
  };

  React.useEffect(() => {
     if (vidRef.current) {
       vidRef.current.onpause = () => setPlaying(false);
     }
  }, [vidRef])

  return (
    <div className={classNames("HypeVideoCell", "Mobile", props.className, { playing })} onClick={playVideo}>
      <video src={props.video} ref={vidRef} controls={true} />
      <div className="thumb" style={{ backgroundImage: `url(${props.thumb})` }}/>
      <div className="text">
        <h2>{props.title}</h2>
      </div>
    </div>
  );
};

interface IHypeVideoProps {
  onMobile: boolean;
}

export const HypeVideo: React.FunctionComponent<IHypeVideoProps> = (props) => {
  return (
    <div className="HypeVideo">
      {!props.onMobile && <><HypeVideoCell
        title="The Future Is Exact"
        className="future-exact"
        video="http://jd-ces-2021.s3.amazonaws.com/media-resources/56774_2021_CES_Digital_Show_Planting_101_Video_v3.mp4"
        clip="http://jd-ces-2021.s3.amazonaws.com/media-resources/TheFutureIsExact-Snippet.mp4"
        thumb={ThumbFuture}
      />
      <HypeVideoCell
        title="Tale of Two Seeds"
        className="two-seeds"
        video="http://jd-ces-2021.s3.amazonaws.com/media-resources/MCC-0012%20-%20Row%20Unit%20(Extended)%20-%20V7.mp4"
        clip="http://jd-ces-2021.s3.amazonaws.com/media-resources/TaleOfTwoSeeds-Snippet.mp4"
        thumb={ThumbTwoSeeds}
      /></>}
      {props.onMobile && <><HypeVideoCellMobile
        title="The Future Is Exact"
        className="future-exact"
        video="http://jd-ces-2021.s3.amazonaws.com/media-resources/56774_2021_CES_Digital_Show_Planting_101_Video_v3.mp4"
        clip="http://jd-ces-2021.s3.amazonaws.com/media-resources/TheFutureIsExact-Snippet.mp4"
        thumb={ThumbFuture}
      />
      <HypeVideoCellMobile
        title="Tale of Two Seeds"
        className="two-seeds"
        video="http://jd-ces-2021.s3.amazonaws.com/media-resources/MCC-0012%20-%20Row%20Unit%20(Extended)%20-%20V7.mp4"
        clip="http://jd-ces-2021.s3.amazonaws.com/media-resources/TaleOfTwoSeeds-Snippet.mp4"
        thumb={ThumbTwoSeeds}
      /></>}
    </div>
  );
};
