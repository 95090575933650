import classNames from "classnames";
import * as React from "react";
import { ModalContext } from "../../components/modal/Modal";
import { OutlineButton } from "../../components/OutlineButton";
import { VideoOverlay } from "../../components/VideoOverlay";
import "./ProgramSchedule.scss";

interface IProgramExpanderProps {
  type: string;
  presenter: string;
  date: string;
  time: string;
}

export const ProgramExpander: React.FunctionComponent<IProgramExpanderProps> = (props) => {
  const [ expanded, setExpanded ] = React.useState(false);
  return (
    <div className={classNames("ProgramExpander", { expanded })}>
      <div className="HeaderBar" onClick={() => setExpanded(!expanded)}>
        <span>{props.type}:</span>
        <span className="Presenter">{props.presenter}</span>
        <div className={classNames("Expander", { expanded })} />
      </div>
      <div className={classNames("Body", { expanded })}>{props.children}</div>
    </div>
  );
};

interface IProgramScheduleProps {}

export const ProgramSchedule: React.FunctionComponent<IProgramScheduleProps> = (props) => {
  const [ video, setVideo ] = React.useState("");

  return (
    <div className={classNames("ProgramSchedule", { showingVideo: Boolean(video) })}>
      <h2>CES 2021 Virtual Program Schedule</h2>
      <div className="Expanders">
        <ProgramExpander
          type="Spotlight Session"
          presenter="Jahmy Hindman and Michael Josh Villanueva"
          date="JANUARY 12"
          time="1:45–2:15 PM ET / 12:45–1:15 PM CT"
        >
          <h2>
            <span>John Deere:</span> Feeding the World With Precision Tech
          </h2>
          <p>
            Farmers face a huge challenge: The world’s population is expected to grow to nearly 10 billion by 2050,
            increasing the global food demand by 50 percent. Join Jahmy Hindman, Chief Technology Officer at Deere &
            Company, and Michael Josh Villanueva, Chief Content Officer at GadgetMatch, to learn how farmers can use
            smart technology and leverage data-driven insights backed by science to feed and fuel the world. Jahmy and
            Michael will discuss how farmers must create predictability out of immense variability to feed this growing
            population with less available land and the variables inherent in farming.
          </p>
          <OutlineButton
            className="green"
            text="Watch Video"
            onClick={() => setVideo("https://player.vimeo.com/video/515801163")}
          />
        </ProgramExpander>
        <ProgramExpander
          type="Spotlight Session"
          presenter="Deanna Kovar and Tim Schofield"
          date="JANUARY 13"
          time="3:15–3:45 PM ET / 2:15–2:45 PM CT"
        >
          <h2>
            <span>John Deere:</span> Inside the Science and Tech Farming
          </h2>
          <p>
            To meet today’s growing global food demands, farmers must produce high-yielding, high-quality crops from
            every single seed they plant. Join Deanna Kovar, Vice President, Production and Precision Ag Production
            Systems at John Deere, and Tim Schofield of Your Tech Simplified to learn how farmers are challenged to meet
            these goals in the face of intense variability, complicated decision-making, and a race against time and
            unpredictable weather. Deanna and Tim will discuss the precision technologies farmers rely on while planting
            — from connectivity to GPS to automation — to plant millions of seeds every year.
          </p>
          <OutlineButton
            className="green"
            text="Watch Video"
            onClick={() => setVideo("https://player.vimeo.com/video/515803147")}
          />
        </ProgramExpander>
        {/* <ProgramExpander type="CTA Panel" presenter="Nancy Post" date="JANUARY 13" time="5:00–5:30 PM ET / 4:00–4:30 PM CT">
          <h2>Broadband For All</h2>
          <p>
            High-speed internet is critical infrastructure for innovation, education, and small business, and yet
            underserved communities globally can’t access this technology. How are innovations and partnerships helping
            to solve this problem? Nancy Post, Director, Embedded Software and Solutions, Intelligent Solutions Group at John Deere, will join a panel of
            experts to discuss.
          </p>
        </ProgramExpander> */}
      </div>
      {video && <VideoOverlay src={video} show={Boolean(video)} onOverlayClicked={() => setVideo("")} />}
    </div>
  );
};
