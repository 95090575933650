import * as React from "react";
import { Header } from "../../../components/Header";
import { OutlineButton } from "../../../components/OutlineButton";
import { ResultsScore } from "../logic/GameCalculator";
import { MsgPlantingSpeed, MsgSeedDepth, MsgSeedSpacing, NextRoundButtonTitle, ResultsOutroMessage } from "../logic/Messages";
import "./ResultsDetail.scss";
import { ScoreBar } from "./ScoreBar";
import { StarBar } from "./StarBar";

interface IResultsDetailProps {
  score: ResultsScore;
  round: number;
  onDismiss: () => void;
  humanErrorRound: boolean;
}

export const ResultsDetail: React.FunctionComponent<IResultsDetailProps> = (props) => {
  const round = props.humanErrorRound ? 0 : props.round;

  return (
    <div className="ResultsDetail">
      <Header />
      <div>
        <ScoreBar score={props.score} />
        <div className="StarBarHeader">Planting Tips</div>
        <StarBar score={props.score.spacing} title="Seed Spacing" details={MsgSeedSpacing[props.round === 3 ? 0 :props.score.spacing]} hideTips={props.humanErrorRound}/>
        <StarBar score={props.score.depth} title="Seed Depth" details={MsgSeedDepth[props.round === 3 ? 0 :props.score.depth]} hideTips={props.humanErrorRound}/>
        <StarBar score={props.score.speed} title="Planting Speed" details={MsgPlantingSpeed[props.round === 3 ? 0 :props.score.speed]} hideTips={props.humanErrorRound}/>
        <div className="ResultsOutro">
          {ResultsOutroMessage(round, props.score)}
        </div>
        <OutlineButton className="yellow" onClick={props.onDismiss} text={NextRoundButtonTitle[round]} />
      </div>
    </div>
  );
};
