export type MajorDecisionChoice = "Early" | "OnTime" | "Later" | "";

const StarsForDepth = (seedDepth: number) => {
  if (seedDepth <= 1) return 1;

  if (seedDepth <= 1.7) return 3;

  if (seedDepth <= 2.2) return 5;

  if (seedDepth <= 2.9) return 4;

  if (seedDepth <= 4) return 2;

  return 0;
};

const StarsForSpacing = (seedSpacing: number) => {
  if (seedSpacing <= 2.9) return 1;

  if (seedSpacing <= 4.5) return 2;

  if (seedSpacing <= 5.9) return 4;

  if (seedSpacing === 6) return 5;

  if (seedSpacing <= 8) return 3;

  return 0;
};

const StarsForSpeed = (plantingSpeed: string) => {
  if (plantingSpeed === "1") return 2;

  if (plantingSpeed === "2") return 4;

  if (plantingSpeed === "3") return 5;

  if (plantingSpeed === "4") return 3;

  if (plantingSpeed === "5") return 1;

  return 0;
};

export const ColorForScore = (score: ResultsScore) => {
  if (score.percentage <= 0.33) return "Red";

  if (score.percentage <= 0.67) return "Yellow";

  return "Green";
};

export const RatingForScore = (score: ResultsScore) => {
  if (score.percentage <= 0.33) return "Suffering";

  if (score.percentage <= 0.67) return "Average";

  return "Above Average";
};

export interface ResultsScore {
  depth: number;
  spacing: number;
  speed: number;
  average: number;
  percentage: number;
  bushels: number;
 decision: MajorDecisionChoice;
}

export const CalculateScore = (depth: number, spacing: number, speed: string, decision: MajorDecisionChoice): ResultsScore => {
  const seedDepthScore = StarsForDepth(depth);
  const seedSpacingScore = StarsForSpacing(spacing);
  const plantingSpeedScore = StarsForSpeed(speed);
  const average = (seedDepthScore + seedSpacingScore + plantingSpeedScore) / 3;
  const percentage = (seedDepthScore + seedSpacingScore + plantingSpeedScore) / 15;
  const bushels = percentage * 230;

  return {
    depth: seedDepthScore,
    spacing: seedSpacingScore,
    speed: plantingSpeedScore,
    average,
    percentage,
    bushels,
    decision
  };
};
