import * as React from "react";
import { Header } from "../../components/Header";
import { isSafari, isMobile } from "react-device-detect";
import "./VrPage.scss";
import { VrNotSupported } from "./VrNotSupported";

interface IVrHomePageProps {}

const VrHomePage: React.FunctionComponent<IVrHomePageProps> = (props) => {
  const supported = !isSafari && !isMobile;

  return (
    <div className="VrPage">
      <Header />
      {!supported && <VrNotSupported />}
      {supported && <iframe
        title="John Deere CES 2021 VR Experience"
        src="http://jd-ces-2021.s3.amazonaws.com/VR/index.html"
        frameBorder="0"
      />}
    </div>
  );
};

export default VrHomePage;
