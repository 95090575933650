import classNames from "classnames";
import * as React from "react";
import { OutlineButton } from "../../components/OutlineButton";
import "./TeaserPanel.scss";

interface ITeaserPanelProps {
  windowHeight: number;
  onLaunch: () => void;
  onMobile: boolean;
}

export const TeaserPanel: React.FunctionComponent<ITeaserPanelProps> = (props) => {
  const [ hidden, setHidden ] = React.useState(false);

  return (
    <div className={classNames("TeaserPanel", { hidden })}>
      <div>
        <h4>John Deere</h4>
        <h1>
          CONSUMER <br /> ELECTRONICS <br />{" "}
          <span>
            SHOW<sup>®</sup> 2021
          </span>
        </h1>
        <OutlineButton
          text="Discover Precision"
          onClick={() => {
            props.onLaunch();
            setHidden(true);
          }}
        />
      </div>
    </div>
  );
};
