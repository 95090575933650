import classNames from "classnames";
import * as React from "react";
import { ColorForScore, ResultsScore } from "../logic/GameCalculator";
import "./ScoreBar.scss";

interface IScoreBarProps {
  score: ResultsScore;
}

export const ScoreBar: React.FunctionComponent<IScoreBarProps> = (props) => {

  return (
    <div className="ScoreBar">
      <div className={classNames("Score", ColorForScore(props.score))}>{Math.round(props.score.bushels)}</div>
      <div className="Label">Bushels Per Acres</div>
      <div className="Bar">
        <div className="Min">0</div>
        <div className="Max">230</div>
        <div className="Indicator" style={{ left: `${props.score.percentage*100}%`}} />
        <div className="Strip">
          <div className="Suffering" />
          <div className="Average" />
          <div className="AboveAverage" />
        </div>
      </div>
    </div>
  );
};
