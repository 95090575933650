import * as React from "react";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import "./MeetTheExperts.scss";

import ImgDan from "./img/DanLeibfried.jpg";
import ImgThan from "./img/ThanHartsock.jpg";
import ImgJenny from "./img/JennyOse.jpg";
import ImgJulian from "./img/JulianSanchez.jpg";
import ImgDeanna from "./img/DeannaKovar.jpg";
import ImgLane from "./img/LaneArthur.jpg";
import ImgJahmy from "./img/JahmyHindman.jpg";
import ImgNancy from "./img/NancyPost.jpg";


import { Header } from "../../components/Header";
import Footer from "../../components/Footer";

interface IExpertSlideProps {
  src: string;
  name: string;
  bio: string;
}

const ExpertSlide: React.FunctionComponent<IExpertSlideProps> = (props) => {
  return (
    <div className="ExpertSlide">
      <img src={props.src} alt={props.name} />
      <h3>{props.name}</h3>
      <p>{props.bio}</p>
    </div>
  );
};

export const MeetTheExperts: React.FunctionComponent = (props) => {
  return (
    <div className="MeetTheExperts">
      <Header />
      <div className="page-content">
        <Carousel
          plugins={[
            "centered",
            "infinite",
            "arrows",
            "clickToChange",
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 4
              }
            }
          ]}

          breakpoints={{
            640: {
              plugins: [
                "centered",
                "infinite",
                "arrows",
                "clickToChange",
               {
                 resolve: slidesToShowPlugin,
                 options: {
                  numberOfSlides: 1
                 }
               },
             ]
            },
            900: {
              plugins: [
                "centered",
                "infinite",
                "arrows",
                "clickToChange",
               {
                 resolve: slidesToShowPlugin,
                 options: {
                  numberOfSlides: 2
                 }
               },
             ]
            }
          }}
        >
          <ExpertSlide
            src={ImgJahmy}
            name="Jahmy Hindman"
            bio="Jahmy Hindman, Ph.D., is the chief technology officer at John Deere, a position he has held since July 2020. He is responsible for building Deere’s “tech stack,” the company’s intuitive end-to-end equipment solution made up of hardware and devices, embedded software, connectivity, data platforms, and applications. He also leads the company’s Intelligent Solutions Group (ISG) and is responsible for the global network of technology/innovation centers and the shared engineering function. Jahmy has more than 20 years of advanced technology, artificial intelligence, product engineering, and manufacturing experience. He earned his bachelor’s degree in mechanical engineering from Iowa State University as well as master’s and doctoral degrees in mechanical engineering from the University of Saskatchewan."
          />
          <ExpertSlide
            src={ImgDeanna}
            name="Deanna Kovar"
            bio="Deanna Kovar is the vice president of Production and Precision Ag Production Systems at John Deere. With expertise in agronomy, economics, products, and technology, she leads a global team focused on identifying opportunities to unlock sustainable value for global corn, soy, small grains, cotton, and sugar customers. During more than 20 years with Deere, she has held many roles in sales, marketing, and customer support, and is very knowledgeable about bringing precision ag technology to market. Deanna earned a bachelor’s degree in agricultural business management from the University of Wisconsin at Madison and a master’s in business administration from Loyola University in Chicago."
          />
          <ExpertSlide
            src={ImgThan}
            name="Than Hartsock"
            bio="Than Hartsock is the Global Director of Corn and Soy Production Systems for John Deere. Through deep knowledge of customers, agronomy, economics, products, and technology, he leads his team in identifying opportunities, creating solution road maps, and driving product plans that unlock new value for customers. Than has served in a variety of product development, strategy, and management roles at John Deere. Most recently, he served as General Manager for See & Spray at Blue River Technology, a Bay Area tech startup acquired by John Deere in 2017. Than earned his bachelor’s in agronomy from Ohio State University, a master’s in soil science from the University of Kentucky, and a master’s in business administration from Northwestern University’s Kellogg School of Management."
          />
          <ExpertSlide
            src={ImgJenny}
            name="Jenny Ose"
            bio="Jenny Ose is the Director of Marketing and Shared Services for the John Deere Ag & Turf Division. Together with her team, she focuses on transforming Deere’s business by building and delivering world-class marketing capabilities and enhanced go-to-market support for our dealers in the U.S. and Canada. Jenny has worked for John Deere for 25 years in a variety of roles and locations. She has experience in all Ag & Turf segments including Turf, Small Ag, Large Ag, and multiple Product and Factory Marketing positions throughout the U.S.; Zweibrücken, Germany; and South America. In addition, Jenny has worked in field sales and territory roles throughout the U.S. and Canada. She earned a bachelor’s degree from Augsburg University and a master’s in business administration from Kellogg School of Management at Northwestern University."
          />
          <ExpertSlide
            src={ImgJulian}
            name="Julian Sanchez"
            bio="Julian Sanchez, Ph.D., is a John Deere Fellow and the Director of Emerging Technologies in the Intelligent Solutions Group (ISG) at John Deere. His team focuses on researching, developing, and supporting the deployment of Deere’s next-generation technologies to improve customer profitability and sustainability. As a seasoned technologist, he has made significant contributions at John Deere including the establishment of UX capabilities and the early deployment of digital solutions in agriculture. Julian holds over 20 patents and has published dozens of peer-reviewed studies in the areas of aviation, agriculture, and medical devices. He earned his bachelor’s in industrial engineering and master’s in industrial and systems engineering from Florida International University and his Ph.D. in human factors from Georgia Institute of Technology."
          />
          <ExpertSlide
            src={ImgDan}
            name="Dan Leibfried"
            bio="Dan Leibfried is the director of automation and autonomy for the Intelligent Solutions Group (ISG) at John Deere. He is responsible for product delivery and commercialization opportunities related to precision technologies in the agriculture, construction, and roadbuilding industry verticals including computer vision, machine learning, and closed loop control system-based technologies. Dan earned his bachelor’s degree in electrical engineering from the University of Iowa. He also holds a master’s in agricultural economics from Purdue University and a master’s in business administration from Indiana University."
          />
          <ExpertSlide
            src={ImgNancy}
            name="Nancy Post"
            bio="Nancy Post is the director for embedded software and solutions for the Intelligent Solutions Group (ISG) at John Deere. She leads a team focused on advancing the design, development, and test of embedded systems with increasing efficiency. She is responsible for leading the delivery of the Precision Ag Technology stack and all embedded solutions for Deere across the Ag & Turf Division. Prior to working at Deere for the last 25 years, Nancy worked at Rockwell International and Compressor Controls Corporation. She earned her bachelor’s in computer science from Mount Mercy College and her master’s in business administration from the University of Phoenix."
          />
          <ExpertSlide
            src={ImgLane}
            name="Lane Arthur"
            bio="Lane Arthur, Ph.D., is the vice president of data, applications, and analytics for the Intelligent Solutions Group (ISG) at John Deere. He oversees the design, delivery, and support of information-enabled solutions that allow farmers, ag solutions providers, and construction owners to leverage data and develop insights to make operations more profitable. Lane is also focused on the creation and execution of the John Deere Operations Center, a set of online and mobile tools that enable access to farm information anytime, anywhere. He is responsible for the technology businesses in Europe and South America. Before Deere, Lane held many broad digital leadership roles involving the deployment of global systems that applied analytical methods and modeling with large data sets to solve business problems, including the VP and CIO for DuPont Pioneer. He earned his bachelor’s in biochemistry from the University of Georgia and his Ph.D. in genetics and development from Columbia University Vagelos College of Physicians and Surgeons."
          />

        </Carousel>
      </div>
      <Footer />
    </div>
  );
};
