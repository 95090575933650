import classNames from "classnames";
import * as React from "react";
import "./RadioButton.scss";
import { ReactComponent as Lock } from "../img/Lock.svg";

interface IRadioButtonProps {
    name: string;
    value: string;
    label: string;
    selected: string;
    onChecked: (checked: string) => void;
    disabled?: boolean;
    locked?: boolean;
    size?: string;
    round?: number;
}

const RadioButton: React.FunctionComponent<IRadioButtonProps> = (props) => {
  return (
    <label className={classNames("radio radio-before", props.size, { disabled: props.disabled, checked: props.value === props.selected, readonly: props.round === 3 })}>
      <span className="radio__input">
        <input type="radio" name={props.name} checked={props.value === props.selected} onChange={() => props.onChecked(props.value)} disabled={props.disabled === true} />
        <span className="radio__control" />
      </span>
      <span className="radio__label">{props.label}</span>
      {props.locked && <Lock className="lock-icon" />}
    </label>
  );
};

export default RadioButton;
