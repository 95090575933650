import * as React from "react";
import "./SeedContainer.scss";

interface ISeedContainerProps {
    depth: number;
    spacing: number;
}

export const SeedContainer: React.FunctionComponent<ISeedContainerProps> = (props) => {
  const x = 3 + ((props.spacing - 8) * (45-3))/ (0-8);
  const y = 10 + ((props.depth - 4) * (65-10))/ (0-4);

  return (
    <div className="SeedContainer">
      <div className="crosshair left" style={{ left: `${x}%`, bottom: `${y}%` }} />
      <div className="crosshair right" style={{ right: `${x}%`, bottom: `${y}%` }} />
      <div className="seed left" style={{ left: `${x}%`, bottom: `${y}%` }} />
      <div className="seed right" style={{ right: `${x}%`, bottom: `${y}%` }} />
    </div>
  );
};
