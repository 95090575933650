import classNames from "classnames";
import React from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";
import {ReactComponent as Download} from "./Download.svg";
export interface ModalContent {
  title?: string;
  definition?: JSX.Element;
  img?: string;
  align?: string;
  lightbox?: boolean;
  video?: string;
  suppressCloseButton?: boolean;
  suppressDownload?: boolean;
  className?: string;
}

interface IModalContext {
  modal: boolean;
  modalContent: ModalContent;
  setModal: (state: boolean) => void;
  handleModal: (content?: ModalContent) => void;
}

const useModal = () => {
  let [ modal, setModal ] = React.useState(false);
  let [ modalContent, setModalContent ] = React.useState<ModalContent>({ title: "", definition: <div /> });

  let handleModal = (content?: ModalContent) => {
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
  };

  return { setModal, modal, handleModal, modalContent };
};

let ModalContext = React.createContext<IModalContext>({} as IModalContext);
let { Provider } = ModalContext;

let ModalProvider: React.FunctionComponent = ({ children }) => {
  let { modal, handleModal, modalContent, setModal } = useModal();
  return (
    <Provider value={{ setModal, modal, handleModal, modalContent }}>
      <Modal />
      {children}
    </Provider>
  );
};

const Modal = () => {
  let { modalContent, handleModal, modal } = React.useContext(ModalContext);
  const dialogRef = React.createRef<HTMLDivElement>();

  const closeModal = (e: React.MouseEvent) => {
    if(e.currentTarget)
      if(dialogRef.current)
        if(!dialogRef.current.contains(e.target as Node))
          handleModal();
  };

  if (modal) {
    return ReactDOM.createPortal(
      <div className={classNames("modal-container", { loaded: modalContent.video || !modalContent.lightbox})} style={{ background: "rgba(0,0,0,0.8)" }} onClick={(e) => closeModal(e)} onLoad={(e) => e.currentTarget.classList.add("loaded")}>
        {!modalContent.suppressCloseButton && <div className="close-button" role="button" onClick={() => handleModal()}>
          &times;
        </div>}

        <div
          className={classNames("modal-dialog", modalContent.className, {
            "has-image": Boolean(modalContent.img),
            lightbox: modalContent.lightbox,
            "has-video": modalContent.video,
          })}
          ref={dialogRef}
        >

          {!modalContent.lightbox && modalContent.img && <div className="modal-image" style={{ backgroundImage: `url(${modalContent.img})` }} />}

          {modalContent.definition && <div
            className={classNames("modal-content", { "has-image": Boolean(modalContent.img) })}
            style={{ textAlign: modalContent.align || "left" }}
          >
            <h2>{modalContent.title}</h2>
            {modalContent.definition}
          </div>}

          {modalContent.lightbox && modalContent.video && <div>
            <video controls autoPlay={true} src={modalContent.video}></video>
            {!modalContent.suppressDownload && <a href={modalContent.video} download="image" target="_blank" rel="noreferrer"><Download /></a>}
          </div>}

          {modalContent.lightbox && modalContent.img && <div>
            <img src={modalContent.img} alt=""/>
            {!modalContent.suppressDownload && <a href={modalContent.img} download="image" target="_blank" rel="noreferrer"><Download /></a>}
          </div>}
        </div>
      </div>,
      document.querySelector("#modal-root")!
    );
  } else return null;
};

export { Modal, ModalProvider, ModalContext };
