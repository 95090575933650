import classNames from "classnames";
import * as React from "react";
import "./StarBar.scss";
import { ReactComponent as DollarSign } from "../img/Green_star.svg";

interface IStarBarProps {
  title: string;
  score: number;
  details: string;
  hideTips: boolean;
}

export const StarBar: React.FunctionComponent<IStarBarProps> = (props) => {
  const [ expanded, setExpanded ] = React.useState(false);
  return (<>
    <div className={classNames("StarBar", { expanded })}>
      <div>{props.title}</div>
      <div className="Rating" data-score={props.score}>
        <DollarSign className="Dollar" />
        <DollarSign className="Dollar" />
        <DollarSign className="Dollar" />
        <DollarSign className="Dollar" />
        <DollarSign className="Dollar" />
      </div>
      <div>
        <div
          className={classNames("Arrow", { expanded: props.hideTips || expanded })}
          onClick={() => {
            if(!props.hideTips)
              setExpanded(!expanded)}
          }
        />
      </div>
    </div>
    <div className={classNames("StarBarDetails", { expanded: props.hideTips ? false : expanded })}>
        {props.details}
    </div>
    </>
  );
};
