import React from "react";
import "./App.scss";
import { Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import GameTest from "./pages/game/Game";
import PlaceholderPage from "./pages/PlaceholderPage";
import { MeetTheExperts } from "./pages/experts/MeetTheExperts";
import GameIntro from "./pages/game/pages/GameIntro";
import { ModalProvider } from "./components/modal/Modal";
import { FactSheetPage } from "./pages/facts/FactSheetPage";
import { HomePage } from "./pages/home/HomePage";
import { MediaResources } from "./pages/media/MediaResources";
import VrHomePage from "./pages/vr/VrPage";
import { usePageTracking } from "./util/use-page-tracking";

function App() {
  usePageTracking();
  return (
    <ModalProvider>
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper"
      >
        <Route exact path="/"><HomePage /></Route>
        <Route exact path="/game"><GameIntro /></Route>
        <Route exact path="/game/nointro"><GameIntro showIntro={false} /></Route>
        <Route exact path="/game/play"><GameTest /></Route>
        <Route exact path="/experts"><MeetTheExperts /></Route>
        <Route exact path="/facts"><FactSheetPage /></Route>
        <Route exact path="/media"><MediaResources /></Route>
        <Route exact path="/vr"><VrHomePage /></Route>
        <Route exact path="/placeholder"><PlaceholderPage /></Route>
      </AnimatedSwitch>
    </ModalProvider>
  );
}

export default App;
