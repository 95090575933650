import { useEffect} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-27659977-3");
    ReactGA.pageview(location.pathname);
  }, [location]);
};