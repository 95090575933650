import * as React from "react";
import "./ScrollIconMobile.scss"

export const ScrollIconMobile: React.FunctionComponent = (props) => {
  return <div className="icon-scroll-mobile">
    <div className="chevron"></div>
    <div className="chevron"></div>
    <div className="chevron"></div>
    <span className="text">Scroll down</span>
  </div>;
};
