import * as React from "react";
import "./JoinUsPanel.scss";

interface IJoinUsPanelProps {}

export const JoinUsPanel: React.FunctionComponent<IJoinUsPanelProps> = (props) => {
  return (
    <div className="JoinUsPanel">
      <div>
        <h1>
            JOIN US AT OUR OUTDOOR BOOTH IN THE CENTRAL PLAZA AT THE <strong><span className="nobr">
              LAS VEGAS
            </span> CONVENTION CENTER</strong> IN 2022.
        </h1>
      </div>
    </div>
  );
};
